/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row } from 'react-flexbox-grid';
import PageLayout from 'components/layout/PageLayout';
import { AppContextProvider } from 'contexts/AppContext';
import RehypeReact from 'rehype-react';
import { I18nProvider } from 'i18n';
// import theme from 'styles/variables.scss';
import s from './CommonLayout.module.scss';

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    section: ({ children }) => <section className={s.section}>{children}</section>,
    'inline-list': ({ children }) => <div className={s.inlineListContainer}>{children}</div>,
  },
}).Compiler;

const Content = ({ page }) => (
  <div className={s.content}>
    <div>{renderAst(page.htmlAst)}</div>
  </div>
);

const CommonLayout = ({ data }) => {
  const page = data.markdownRemark;
  const { title, metaDescription, facebookUrl, instagramUrl, tikTokUrl, featuredImage } = page.frontmatter;

  return (
    <AppContextProvider>
      <I18nProvider>
        <PageLayout
          {...{ title, facebookUrl, instagramUrl, tikTokUrl, metaDescription, featuredImage }}
          metaTitle={title}
        >
          <Grid>
            <Row>
              <Content page={page} />
            </Row>
          </Grid>
        </PageLayout>
      </I18nProvider>
    </AppContextProvider>
  );
};

export default CommonLayout;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      htmlAst
      frontmatter {
        title
        metaDescription
        facebookUrl
        instagramUrl
        tikTokUrl
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 660) {
              ...GatsbyImageSharpSizes
            }
            resize(width: 800) {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;
